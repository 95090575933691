import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/GraphQLErrorList";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import { mapEdgesToNodes, toPlainText } from "../lib/helpers";
import { errorType, mediaType } from "../types";
import MediaDetail from "../components/MediaDetail";

export const query = graphql`
  query MediaTemplateQuery($id: String!, $relatedMediaIds: [String] = []) {
    media: sanityMedia(id: { eq: $id }) {
      ...Media
    }
    relatedMedia: allSanityMedia(filter: { id: { in: $relatedMediaIds } }) {
      ...MediaEdge
    }
  }
`;

const MediaTemplate = ({ data, errors }) => {
  const media = data && data.media;
  const relatedMediaNodes =
    data && data.relatedMedia && mapEdgesToNodes(data.relatedMedia);
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}

      {media && (
        <SEO
          title={media.title || "Untitled"}
          // eslint-disable-next-line no-underscore-dangle
          description={toPlainText(media._rawExcerpt)}
          image={media.image}
        />
      )}
      {errors && <GraphQLErrorList errors={errors} />}
      {media && <MediaDetail media={media} relatedMedia={relatedMediaNodes} />}
    </Layout>
  );
};

MediaTemplate.defaultProps = {
  errors: null,
};

MediaTemplate.propTypes = {
  data: PropTypes.shape({
    media: mediaType,
    relatedMedia: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({ node: mediaType })),
    }),
  }).isRequired,
  errors: PropTypes.arrayOf(errorType),
};

export default MediaTemplate;
